import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createSelectStorefrontImage } from '../../../actions/popup';

import Header from '../Header';
import Footer from '../Footer';
import { usePublicViewEntityContext } from '../../../context/PublicViewEntityProvider';
import { usePublicViewTemplateDataContext } from '../../../context/PublicViewTemplateDataProvider';

function getStateFromProps(props) {
  const template_data = props.template_data;
  const title = (template_data.title || { value: props.title }).value;
  const button_text = (template_data.button_text || { value: 'Browse all of our Products' }).value;
  const darken = (template_data.darken || { value: 1 }).value;
  return {
    title,
    button_text,
    darken
  };
}

function Editable({
  state,
  handleClickShop,
  handleClickTitle,
  hideIntro = false,
  isPreviewing = false,
}) {
  const dispatch = useDispatch();
  const { entityId, entityType, entityBuyInventory } = usePublicViewEntityContext();
  const { template_data, templateColor: template_color } = usePublicViewTemplateDataContext();

  function onChangeBackground() {
    dispatch(createSelectStorefrontImage(entityId, entityType, 'background'));
  }

  const backgroundUrl = (template_data.background || {}).value;
  const backgroundStyle = { backgroundImage: backgroundUrl ? `url('${backgroundUrl}')` : "url('/images/template2-bg.jpg')" };

  return (
    <div id="home">
      <Header
        isEditable={true}
        hideIntro={hideIntro}
        isPreviewing={isPreviewing}
        buy_inventory={entityBuyInventory}
      />
      <div id="template2">
        <div className="shop-hdr" style={{
          position: 'relative',
          ...(entityType === 'ORDER' ? { height: 'calc(100vh - 282px)' } : {}),
          ...backgroundStyle
        }}>
          <div className="darken" style={state.darken == 0 ? { position: 'relative', height: '70vh', background: 'none' } : { position: 'relative', height: '70vh' }}>
              <a className="button" onClick={() => onChangeBackground()} style={{ position: 'absolute', float: 'left', border: 'none' }}>Change Image</a>
            <div className="headline">
              <h1 onClick={handleClickTitle} style={{
                minWidth: '300px',
                minHeight: '50px',
                cursor: 'title' === state.editing ? 'text' : 'pointer',
              }}>
                {state.title}
              </h1>
              <a id="btn" className="browse-products-btn" style={{ color: template_color }} onClick={handleClickShop}>
                {state.button_text}
              </a>
            </div>
          </div>
          {entityType !== 'ORDER' && <Footer isEditable={true} />}
        </div>
        {entityType === 'ORDER' && <Footer isEditable={true} />}
      </div>
    </div>
  );
}

function NotEditable({
  state,
  handleClickShop,
  hideIntro = false,
  isPreviewing = false,
}) {
  const { entityBuyInventory, entityType } = usePublicViewEntityContext();
  const { template_data, templateColor: template_color } = usePublicViewTemplateDataContext();
  const backgroundUrl = (template_data.background || {}).value;
  const backgroundStyle = backgroundUrl ? { backgroundImage: `url('${backgroundUrl}')` } : null;

  return (
    <div id="home">
      <Header
        isEditable={false}
        hideIntro={hideIntro}
        isPreviewing={isPreviewing}
        buy_inventory={entityBuyInventory}
      />
      <div id="template2">
        <div className={"shop-hdr " + (entityType + '-shop')} style={backgroundStyle}>
          <div className="darken" style={state.darken == 0 ? { background: 'none'} : null}>
            <div className="headline">
              <h1>{state.title}</h1>
              <a id="btn" className="browse-products-btn" style={{ color: template_color }} onClick={handleClickShop}>{state.button_text}</a>
            </div>
          </div>
          {entityType !== 'ORDER' && <Footer isEditable={false} />}
        </div>
        {entityType === 'ORDER' && <Footer isEditable={true} />}
      </div>
    </div>
  );
}

const Template2 = ({
  isEditable,
  hideIntro = false,
  isPreviewing = false,
}) => {
  const navigate = useNavigate();
  const { baseEntityUrl, title, } = usePublicViewEntityContext();
  const { template_data, } = usePublicViewTemplateDataContext();

  const [state, setState] = useState({
    editing: false,
    ...getStateFromProps({ title, template_data }),
  });

  const onUpdateField = (field, value) => setState(s => ({ ...s, [field]: value }));

  useEffect(() => {
    setState((s) => ({
      ...s, ...getStateFromProps({ title, template_data })
    }));
  }, [template_data, title]);

  function handleClickShop(e) {
    e.preventDefault();
    if (isEditable) {
      e.stopPropagation();
      onUpdateField('editing', 'button_text');
    } else {
      navigate(baseEntityUrl);
    }
  }

  function handleClickTitle(e) {
    e.preventDefault();
    if (isEditable) {
      e.stopPropagation();
      onUpdateField('editing', 'title');
    }
  }

  return (
    isEditable
      ? <Editable
          state={state}
          handleClickShop={handleClickShop}
          handleClickTitle={handleClickTitle}
          hideIntro={hideIntro}
          isPreviewing={isPreviewing}
        />
      : <NotEditable
          state={state}
          handleClickShop={handleClickShop}
          hideIntro={hideIntro}
          isPreviewing={isPreviewing}
        />
  );
};

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Template2);
